var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.item.user)?_c('div',{staticClass:"talent row font-normal",class:_vm.type == 'recommend' ? 'talent-recommend' : ''},[_c('div',{staticClass:"row pointer",on:{"click":function($event){return _vm.submitClick(
          '/layout/mangeCv',
          _vm.item.user_id,
          _vm.item.position,
          _vm.item.type,
          _vm.item.position_id
        )}}},[_c('img',{staticClass:"user-avatar",attrs:{"src":_vm.item.user.avatar_url ? _vm.item.user.avatar_url : _vm.avatar_url,"alt":""}}),_c('div',{staticClass:"user-detail"},[(_vm.item.type == 3)?_c('div',{staticClass:"el-example-img"},[_c('img',{attrs:{"src":require("../../../assets/glxt/46.png"),"alt":""}})]):_vm._e(),_c('div',{staticClass:"user-name row-center"},[(_vm.item.user.privates.protect_name == 0)?_c('span',[_vm._v(_vm._s(_vm.item.user.name))]):_c('span',[_vm._v(_vm._s(_vm.item.user.name.slice(0, 1))+_vm._s(_vm.item.user.gender.value == 1 ? "先生" : "女士"))]),(_vm.type !== 'recommend')?_c('div',{staticClass:"text-blue flex-center"},[_vm._v(" "+_vm._s(_vm.item.type == 1 ? "平台推送" : "自主投递")+" ")]):_vm._e(),(_vm.type !== 'recommend')?_c('span',[_vm._v(_vm._s(_vm.item.online_status))]):_vm._e()]),_c('div',{staticClass:"user-year-job row-center"},[_c('span',[_vm._v(_vm._s(_vm.item.user.gender.text))]),_c('div',[_vm._v("|")]),_c('span',[_vm._v(_vm._s(_vm.item.user.other_info.age))]),_c('div',[_vm._v("|")]),_c('span',[_vm._v("工作"+_vm._s(_vm.item.user.other_info.work_year)+"年")]),_c('div',[_vm._v("|")]),_c('span',[_vm._v(_vm._s(_vm.item.user.highest_education))]),_c('div',[_vm._v("|")]),_c('span',[_vm._v(_vm._s(_vm.item.user.other_info.job_status))])]),(_vm.item.user.job_intention.length > 0)?_c('div',{staticClass:"talent-expect row-center"},[_c('span',[_vm._v("期望：")]),_c('span',[_vm._v(_vm._s(_vm.item.user.job_intention[0].province))]),_c('div',[_vm._v("|")]),_c('span',[_vm._v(_vm._s(_vm.item.user.job_intention[0].salary_min)+"-"+_vm._s(_vm.item.user.job_intention[0].salary_max))])]):_vm._e(),(_vm.type !== 'recommend')?_c('div',[_vm._v(" "+_vm._s(_vm.item.type == 1 ? "系统匹配职位" : "投递职位")+"："+_vm._s(_vm.item.position)+" ")]):_vm._e()])]),_c('div',{staticClass:"talent-middle pointer",on:{"click":function($event){return _vm.submitClick(
          '/layout/mangeCv',
          _vm.item.user_id,
          _vm.item.position,
          _vm.item.type,
          _vm.item.position_id
        )}}},[_c('div',{staticClass:"row work-talent"},[(_vm.item.user.work.length > 0)?_c('img',{attrs:{"src":require("../../../assets/glxt/20.png"),"alt":""}}):_vm._e(),(_vm.item.user.work.length > 0)?_c('div',[_c('div',{staticClass:"work-time row-center"},[_c('div',[_vm._v(_vm._s(_vm.item.user.work[0].job_times))]),_c('div',[_vm._v(_vm._s(_vm.item.user.work[0].company_name))]),_c('div',[_vm._v(_vm._s(_vm.item.user.work[0].work_name))])]),(_vm.item.user.work.length > 1)?_c('div',{staticClass:"work-time row-center"},[_c('div',[_vm._v(_vm._s(_vm.item.user.work[1].job_times))]),_c('div',[_vm._v(_vm._s(_vm.item.user.work[1].company_name))]),_c('div',[_vm._v(_vm._s(_vm.item.user.work[1].work_name))])]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"row work-talent"},[(_vm.item.user.school.length > 0)?_c('img',{attrs:{"src":require("../../../assets/glxt/5.png"),"alt":""}}):_vm._e(),_c('div',[(_vm.item.user.school.length > 0)?_c('div',{staticClass:"work-time row-center"},[_c('div',[_vm._v(_vm._s(_vm.item.user.school[0].school_times))]),_c('div',{staticClass:"space-between"},[_c('span',[_vm._v(_vm._s(_vm.item.user.school[0].education))]),_c('span',[_vm._v(_vm._s(_vm.item.user.school[0].school_name))])]),_c('div',[_vm._v(_vm._s(_vm.item.user.school[0].major))])]):_vm._e()])])]),_c('div',{staticClass:"talent-right row-center"},[(_vm.heardTabIndex !== 1)?_c('div',{staticClass:"bg-blue text-white flex-center pointer",class:_vm.heardTabIndex == 3 && _vm.item.link_status == 1 ? 'manage-botton-one' : '',on:{"click":function($event){return _vm.sendClick(_vm.item.type)}}},[(_vm.heardTabIndex == 3)?_c('span',[_vm._v(_vm._s(_vm.item.link_status == 0 ? "立即聊" : "继续沟通"))]):_vm._e(),(_vm.heardTabIndex != 3)?_c('span',[_vm._v("立即聊")]):_vm._e()]):_vm._e(),(_vm.heardTabIndex == 1)?_c('div',{staticClass:"text-blue font-normal flex-center manage-botton pointer",on:{"click":function($event){return _vm.submitClick(
            '/layout/interactive',
            _vm.item.user_id,
            _vm.item.position,
            _vm.item.type,
            _vm.item.position_id
          )}}},[_vm._v(" 继续沟通 ")]):_vm._e(),_c('div',{staticClass:"bg-blue text-white flex-center pointer",class:_vm.item.user.privates.protect_phone == 1 ? 'privacy-class' : '',on:{"mouseenter":function($event){return _vm.mouseenter(_vm.item.type)},"mouseleave":_vm.mouseleave,"click":function($event){return _vm.mouseClick(_vm.item.type)}}},[_c('div',{staticClass:"position-relative"},[_c('span',[_vm._v("电话沟通")]),(_vm.privacy && _vm.index == _vm.mouseenterIndex)?_c('div',{staticClass:"position-absolute privacy"},[_c('img',{attrs:{"src":require("../../../assets/glxt/19.png"),"alt":""}}),_c('div',{staticClass:"privacy-text font-normal position-absolute left-0 top-0"},[_vm._v(" 对方开启隐私保护，无法电话沟通，请使用聊天功能 ")])]):_vm._e()])]),(_vm.heardTabIndex !== 2)?_c('div',{staticClass:"bg-blue text-white flex-center pointer",on:{"click":function($event){return _vm.delectClick(_vm.item.type)}}},[_vm._v(" "+_vm._s(_vm.item.type == 1 ? "忽略" : "不合适")+" ")]):_vm._e(),(_vm.heardTabIndex== 2)?_c('div',{staticClass:"bg-blue text-white flex-center pointer",on:{"click":function($event){return _vm.delectClick(_vm.item.type)}}},[_vm._v(" "+_vm._s(_vm.item.type == 1 ? "取消忽略" : "取消不合适")+" ")]):_vm._e()])]):_vm._e(),_c('mask-model',{ref:"maskModel",attrs:{"imgShow":true,"title":"联系电话","width":"482px","btnCancel":false}},[_c('div',{staticClass:"privacy-phone text-main font-lighter"},[_vm._v(" "+_vm._s(_vm.phone ? _vm.phone : "暂无联系方式")+" ")])]),_c('mask-model',{ref:"maskHint",attrs:{"title":"温馨提示","sureContent":"去认证","btnCancel":false,"width":"482px"},on:{"submitClick":function($event){return _vm.submitClick('/layout/account')}}},[_c('div',{staticClass:"privacy-hint text-main font-normal"},[_vm._v(" 系统检测到您还未进行企业认证，认证后即可查看人才信息 ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }